import React from 'react';
import { checkUpdate, UPDATE_CODE } from '../utils';

export const ExitKiosk = () => window.KioskPlugin
    ? (
        <BigButton backgroundColor="red" borderColor="#841607" onClick={() => window.confirm('EXIT?') && window.KioskPlugin.exitKiosk()}>
            EXIT Kiosk Mode
        </BigButton>)
    : null;

export const ForceReload = () => (
    <BigButton backgroundColor="#D0D0D0" borderColor="#696969" style={{ color: "#000" }} onClick={() => window.location.reload(true)}>
        Force Reload
    </BigButton>);

export class CheckUpdate extends React.Component {
    checkUpdate () {
        checkUpdate()
            .then((code) => {
                if (code === UPDATE_CODE.VERSION_UP_TO_UPDATE) {
                    alert('App is up-to-date');
                }
            })
            .catch(() => {
                alert('ERROR updating');
            })
    }
    render() {
        return (
            <BigButton backgroundColor="orange" borderColor="#DB8C28" onClick={this.checkUpdate}>
                Check Update
            </BigButton>)
    }
}
export const BigButton = ({children, backgroundColor, borderColor, onClick, style, disabled}) =>
    <button
        style={{
            minWidth:'200px', height:'70px', zIndex:99999, margin:'auto 10px', fontWeight:'bold',
            backgroundColor, border: `1px solid ${borderColor}`, ...style }}
        className="btn btn-primary"
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </button>
