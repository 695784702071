import React from 'react';

import { lengthClassH1 } from '../helpers';
import { BigButton } from './Buttons';
import { apiPost } from '../requestUtils';

export class ReversePairing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {digits: [], error: null, focused: false};
        this.send = this.send.bind(this);
    }
    send () {
        this.setState({ loading: true });
        apiPost( `package/pair/${this.state.digits.join('')}`)
            .then(({ deviceId, deviceName }) => this.props.setDevice({ deviceId, deviceName }))
            .catch(({ message, responseJson }) => {
                this.setState({ loading: false, error: (responseJson && responseJson.message) || message })
            })
    }

    render() {
        const { loading, error, focused } = this.state;
        const sentDisabled = this.state.digits.length < 6 || this.state.digits.includes('') || loading || error;

        return (
            <div className="page showing">
                <div className={focused ? 'focused' : 'v-center-wrapper'}>
                    <div className="question reverse-pairing text-line">
                        <div className="container">
                            <h1 className={lengthClassH1('Reverse pairing')}>Activate device</h1>
                            {error && <h2>{error}</h2>}
                            {loading && <h2>Loading...</h2>}
                            <div className="options-wrap">
                                <PassBoxes
                                    onFocus={(e) => this.setState({ focused: true })}
                                    onBlur={(e) => this.setState({ focused: false })}
                                    onChange={(digits) => this.setState({ digits, error: null })}
                                />
                                <BigButton
                                    disabled={sentDisabled}
                                    backgroundColor={sentDisabled ? '#ececec' : '#1abc9c'}
                                    borderColor="rgb(40 114 99)"
                                    style={{ marginTop: '72px', color: sentDisabled ? '#808080' : '#000' }}
                                    onClick={this.send}
                                >
                                    Send
                                </BigButton>
                            </div>
                            {this.props.switchBack && (
                                <BigButton
                                    disabled={loading}
                                    style={{ marginTop: '20px', position: 'fixed', bottom: '20px', right: '20px' }}
                                    onClick={this.props.switchBack}
                                >
                                    Switch to manual pairing
                                </BigButton>)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class PassBoxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {values: []};
        this.inputRefs = [];
        this.inputs = [];
        for (let i = 0; i<6; i++) {
            this.inputs.push(`digit-${i}`);
            this.inputRefs.push(React.createRef());
        }
        this.setValueForIdx = this.setValueForIdx.bind(this);
        this.clearValues = this.clearValues.bind(this);
    }
    setValueForIdx(idx, value) {
        const newValues = [...this.state.values];
        newValues[idx] = value
        this.props.onChange(newValues);
        this.setState({ values: newValues });
    }
    clearValues() {
        this.props.onChange([]);
        this.setState({ values: [] });
    }
    render() {
        const { values } = this.state;

        return (
            <div className="passcode-area">
                {this.inputs.map((inputName, idx) =>
                    <input
                        key={inputName}
                        name={inputName}
                        ref={this.inputRefs[idx]}
                        onFocus={(e) => {
                            this.props.onFocus(e);
                            e.target.select();
                        }}
                        onBlur={this.props.onBlur}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setValueForIdx(idx, value);
                            if (value && idx === 5) {
                                e.target.blur();
                            }
                            if (idx > 0 && !value && e.target.previousElementSibling) {
                                e.target.previousElementSibling.focus();
                            }
                        }}
                        value={values[idx] || ''}
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        onInput={handleInput}
                        onKeyDown={makeHandleKeyDown(
                            this.inputRefs,
                            (value) => this.setValueForIdx(idx, value),
                            this.clearValues
                        )}
                    />)}
            </div>
        );
    }
}


const handleInput = function(e) {
    const { value, nextElementSibling } = e.target;
    if (value === '' || !nextElementSibling) {
        return;
    }
    nextElementSibling.focus();
}
const makeHandleKeyDown = (inputRefs, setValue, clearAll) => function handleKeyDown(event) {
    const {nextElementSibling, previousElementSibling, value} = event.target;
    //Right Arrow Key
    if (event.keyCode === 39 && nextElementSibling) nextElementSibling.focus();
    //Left Arrow Key
    //Add Highlight
    if (event.keyCode === 37 && previousElementSibling) previousElementSibling.focus();
    //Backspace Key
    if (event.keyCode === 8 && event.metaKey) {
        console.log('FOUND!!! Ctrl + Backspace = clear all');
        clearAll()
        if (inputRefs[0].current) inputRefs[0].current.focus();
    } else if (event.keyCode === 8) {
        setValue(value);
        if (value === '' && previousElementSibling) {
            previousElementSibling.focus();
            return;
        }
        event.target.value = '';
    }
    if (event.key && event.key.length === 1 && event.key !== value && nextElementSibling && nextElementSibling.value) {
        setValue(event.key);
        if (nextElementSibling) nextElementSibling.focus();
    }
}
