import React from 'react';
import Switch from "react-switch";

class NetworkSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { mobileDataLoading: true };
        this.toggleMobileData = this.toggleMobileData.bind(this);
        this.toggleWifi = this.toggleWifi.bind(this);
        this.scanForNetworks = this.scanForNetworks.bind(this);
    }
    componentDidMount() {
        if (window.cordova && window.cordova.plugins && window.cordova.plugins.MobileData) {
            console.log('window.cordova.plugins.MobileData.check');
            window.cordova.plugins.MobileData.check(
                (data) => {
                    this.setState({ mobileDataEnabled: data.enabled, mobileDataLoading: false });
                },
                (err) => console.log('MobileData', { err })
            );
        }
        this.scanForNetworks();
    }
    toggleMobileData () {
        this.setState({ mobileDataLoading: true });
        const funcKey = this.state.mobileDataEnabled ? 'disable' : 'enable'
        window.cordova.plugins.MobileData[funcKey](
            (data) => {
                this.setState({ mobileDataEnabled: data.enabled, mobileDataLoading: false });
            },
            (err) => console.log({ err })
        );
    }
    async toggleWifi () {
        const functionName = this.state.wifiEnabled ? 'disableWifi' : 'enableWifi';
        this.setState({ isCheckingWifi: true });
        try {
            await window.WifiWizard2[functionName]();
            const isWifiEnabledNow = await window.WifiWizard2.isWifiEnabled();
            if (!isWifiEnabledNow && !this.state.wifiEnabled) {
                await new Promise((resolve) => setTimeout(resolve, 3000));
            }
            await this.scanForNetworks();
        } catch (err) {
            console.error('toggleWifi err', err);
            this.setState(({ wifiEnabled }) => ({ wifiEnabled, isCheckingWifi: false }));
        }
    }
    async scanForNetworks () {
        if (window.WifiWizard2) {
            this.setState({ isCheckingWifi: true });
            const wifiEnabled = await window.WifiWizard2.isWifiEnabled();
            this.setState({ wifiEnabled, isCheckingWifi: false });
            if (wifiEnabled) {
                this.setState({ scanning: true });
                try {
                    const networks = await window.WifiWizard2.scan();
                    this.setState({ networks, scanning: false });
                    const currentNetwork = await window.WifiWizard2.getConnectedSSID();
                    this.setState({ currentNetwork });
                } catch (catchResult) {
                    this.setState({ catchResult });
                }
            } else {
                this.setState({ networks: null, currentNetwork: null, scanning: false });
            }
        }
    }
    render () {
        const {mobileDataLoading, mobileDataEnabled, isCheckingWifi, wifiEnabled} = this.state;
        return <div>
            <label>
                <h2>
                    <span>Mobile Data </span>
                    <Switch
                        onChange={this.toggleMobileData} disabled={mobileDataLoading} checked={mobileDataEnabled}
                        height={20}
                        width={40}
                    />
                </h2>
            </label>
            <hr />
            <div style={{ fontSize: '14px', paddingBottom: '50px' }}>
                <label>
                    <h2>
                        <span>WiFi </span>
                        <Switch
                            onChange={this.toggleWifi} disabled={isCheckingWifi} checked={wifiEnabled}
                            height={20}
                            width={40}
                        />
                    </h2>
                </label>
                <div className="container">
                    <h3><b>Networks</b></h3>
                    {this.state.networks && this.state.networks.map((network) =>
                        <NetworkRow
                            key={network.BSSID}
                            network={network}
                            isConnecting={this.state.isConnecting}
                            setIsConnecting={(isConnecting) => this.setState({ isConnecting })}
                            currentNetwork={this.state.currentNetwork}
                            setCurrentNetwork={(currentNetwork) => this.setState({ currentNetwork })}
                        />)}
                </div>
            </div>
        </div>;
    }
}

class NetworkRow extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {};
        this.connect = this.connect.bind(this);
        this.open = this.open.bind(this);
    }
    open(err) {
        const password = window.prompt(!err ? 'Password?' : 'Failed to connect. Provide password again:')
        if (password) {
            this.connect(password);
        } else {
            this.setIsConnecting(false);
        }
    }
    connect(password) {
        const { network } = this.props;
        this.setIsConnecting(true);
        window.WifiWizard2.connect(
            network.SSID,
            true,
            this.state.password || password,
            network.capabilities.match('WPA') ? 'WPA' : network.capabilities.match('WEP') ? 'WEP' : undefined,
        ).then(() => {
            this.setIsConnecting(false);
            this.props.setCurrentNetwork(network.SSID);
        })
        .catch((err) => {
            this.setIsConnecting(false);
            this.open(true)
        })
    }
    setIsConnecting(isConnecting) {
        this.setState({ isConnectingToThis: isConnecting });
        if (this.props.setIsConnecting) {
            this.props.setIsConnecting(isConnecting);
        }
    }
    render() {
        const { network, currentNetwork, isConnecting } = this.props;
        const { isConnectingToThis } = this.state;
        this.isOpenConnection = !network.capabilities.match(/WPA|WEP/);
        const isConnectedToThis = currentNetwork === network.SSID;
        return (
            <div className={`row wifi-row${isConnectedToThis ? ' connected' : ''}${isConnectingToThis ? ' connecting' : ''}`}>
                <div className="col col-xs-5">{network.SSID}{isConnectedToThis ? ' (Connected)' : ''}</div>
                <div className="col col-xs-2">{network.level}</div>
                <div className="col col-xs-5">
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={isConnectedToThis || isConnecting}
                        onClick={this.isOpenConnection ? this.connect : () => this.open()}
                    >
                        Connect{(isConnectedToThis && 'ed') || (isConnectingToThis && 'ing')}
                    </button>
                </div>
                {this.state.error && JSON.stringify(this.state.error)}
            </div>)
    }
}

export default NetworkSection;
