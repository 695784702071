import React from "react";

import { ForceReload } from './Buttons';

class InfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { battLvl: null, charging: null };
    }
    componentDidMount() {
        if (navigator.getBattery && !this.state.battLvl) {
            let batteryPromise = navigator.getBattery();
            if (batteryPromise && batteryPromise.then) {
                batteryPromise.then((batt) => this.setState({charging: batt.charging, battLvl: batt.level}))
            }
        }
    }
    render () {
        let batteryInfo;

        if (this.state.battLvl) {
            batteryInfo = [<h3 key="batCharging"><b>Charging:</b> {this.state.charging ? "Yes" : "No"}</h3>,
                <h3 key="batLvl"><b>Battery Level:</b> {this.state.battLvl * 100}%</h3>]
        }
        const { deviceId, switchToAdvanced } = this.props;
        return <div>
            <h3><b>DevId:</b> {deviceId.substr(-5)}</h3>
            <h3><b>Connection:</b> {navigator.onLine ? 'Connected' : 'Disconnected'}</h3>
            {batteryInfo}
            <br />
            <ForceReload />
            <br />
            Window size: {window.innerWidth}x{window.innerHeight}
            <br />
            {window['fully'] && <button onClick={() => window['fully'].exit()}>EXIT KIOSK-BROWSER</button>}
            {switchToAdvanced && <button onClick={switchToAdvanced}>Switch to Advanced</button>}
        </div>;
    }
}

export default InfoSection;
