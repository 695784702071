import React from 'react';

import { logError } from '../helpers';
import FinalPairingScreen from './FinalPairingScreen';
import AddToHomeScreenIpad from './AddToHomeScreenIpad';
import AddToHomeScreenAndroid from './AddToHomeScreenAndroid';
import { apiRequest } from '../requestUtils';

class PairingScreen extends React.Component {
    componentDidMount() {
        this._retrieveDeviceId(this.props.pairingCode);
    }
    componentWillUnmount() {
        clearTimeout(this.deviceIdWait);
    }

    _retrieveDeviceId(code) {
        apiRequest(`package/register/${code}`, {method: 'POST'})
            .then((data) => {
                if (!data.deviceId) {
                    this.deviceIdWait = setTimeout(this._retrieveDeviceId.bind(this), 30000, code);
                    return;
                }

                this.props.setDevice(data);
            })
            .catch((error) => logError('Request failed', error));
    }
    render() {
        let Component;
        if (window.webAppDevType === 'ipad' || window.webAppDevType === 'iphone') {
            Component = window.navigator && window.navigator.standalone ? FinalPairingScreen : AddToHomeScreenIpad;
        } else {
            Component = window.matchMedia && window.matchMedia('(display-mode: fullscreen), (display-mode: standalone)').matches
                ? FinalPairingScreen
                : AddToHomeScreenAndroid;
        }

        return (
            <div className={"h-window pairing-screen" + (this.props.hidden ? ' d-none' : '')}>
                <div className="page showing">
                    <div className="v-center-wrapper">
                        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/css/gr-dark.css'}/>
                        <Component {...this.props} />
                    </div>
                </div>
            </div>);
    }
}

export default PairingScreen;
