import React from "react";

const Logo = (props) => {
    const bigLogo = process.env.PUBLIC_URL + (props.baseTheme === "Light" ? '/logo.png' : '/logo-light.png');
    return (
        <div className="logo-wrapper">
            <img src={bigLogo} alt="GreatRate" className="gr-logo"/>
            <img src={process.env.PUBLIC_URL + '/logo-small.ico'} alt="GreatRate" className="gr-logo-small"/>
            {!props.hideWeUse && (
                <div className="gr-we-use-gr">
                    We use <a href="http://greatrate.se" target="_blank" rel="noopener noreferrer">GreatRate</a>
                </div>)}
        </div>
    );
};

export default Logo;
