import React from "react";

import iconDots from '../css/pairing/icon-dots.png';
import iconOpen from '../css/pairing/icon-open.png';


const AndroidAddToHomeInstructions = (props) => (
    <React.Fragment>
        <div className="row">
            <div className="col-xs-3 col-sm-4 text-right icon-wrapper">
                <img src={iconDots} alt="Android Menu icon" />
            </div>
            <div className="col-xs-9 col-sm-8 text-instructions">
                <p>1. Press this button and<br className="visible-lg-block"/> “Add to home screen”.</p>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-3 col-sm-4 text-right icon-wrapper">
                <img src={iconOpen} alt="Open App icon" />
            </div>
            <div className="col-xs-9 col-sm-8 text-instructions">
                <p>2. Open app from home screen.</p>
            </div>
        </div>
    </React.Fragment>);

export default AndroidAddToHomeInstructions;
