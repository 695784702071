import React from "react";
import ReactDOM from "react-dom/client";
// import initReactFastclick from "react-fastclick";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { Home } from "./App";
import GrRouter from "./GrRouter";
// import { DSN } from './constants';

// if (DSN) {
//     // We recommend adjusting the tracesSampleRate value in production, or using tracesSampler for finer control
//     Sentry.init({ dsn: DSN, integrations: [new Integrations.BrowserTracing()], tracesSampleRate: 1.0 });
// }
// // Android Mobile
// initReactFastclick();

if (!window.location.host.match(/^(localhost|(dev|stage).portal)/)) document.oncontextmenu = () => false;

const root = ReactDOM.createRoot(document.getElementById('root'));

if (window.cordova) {
    document.addEventListener("deviceready", function() {
        if (window.AndroidFullScreen) {
            window.AndroidFullScreen.immersiveMode();
        }
        // var Sentry = window.cordova.require("sentry-cordova.Sentry");
        // Sentry.init({ dsn: 'https://08352f3fb5d54ccbab6f645df180d44e@o460533.ingest.sentry.io/5460923' });
    }, false);
    root.render(<Home />);
} else {
    root.render(<GrRouter />);
}
