import { API_HOST } from './constants';

export class ServerError extends Error {
    name = 'ServerError';
    constructor(status, responseJson, ...args) {
        super(...args);
        this.responseJson = responseJson;
        this.status = status;
    }
}

export class UnexpectedError extends Error {
    name = 'UnexpectedError';
    constructor(status, responseJson, ...args) {
        super(...args);
        this.responseJson = responseJson;
        this.status = status;
    }
}

export function check_status(response) {
    const { status } = response;
    if (status >= 200 && status < 300) {
        return Promise.resolve(response)
    }

    return response.json().then((responseJson) => {
        if (responseJson === "unkown exception") {
            Promise.reject(new UnexpectedError(status, responseJson, response.statusText));
        } else {
            Promise.reject(new ServerError(status, responseJson, response.statusText));
        }
    });
}

const JSON_HEADERS = new Headers({"Content-Type": "application/json"});
const parseJson = (response) => response.status === 204 ? response : response.json();

export const apiRequest = (path, options) => fetch(`${API_HOST}/api/v1/${path}`, options)
    .then(check_status)
    .then(parseJson);

export const apiPost = (path, body) => apiRequest(path, {method: 'POST', headers: JSON_HEADERS, body: body && JSON.stringify(body)});
export const apiDelete = (path) => apiRequest(path, {method: 'DELETE'});