import { SurveyManager } from './App';

export class Widget extends SurveyManager {
    constructor(props) {
        super(props);
        this.isWidget = true;
    }
    _finishSurvey() {
        window.parent.postMessage(JSON.stringify({
            status: 'finished',
            questionnaireId: this._getCurrentQuestionnaire().id
        }), "*")
    }

    _submitAnswerPackage(answerPackage) {
        super._submitAnswerPackage(answerPackage);
        localStorage.setItem('surveyData', JSON.stringify(this.refs.survey.surveyData));
        window.parent.postMessage(JSON.stringify({
            status: 'questionSubmitted',
            surveyId: this._getCurrentQuestionnaire().id,
            questionId: answerPackage.questionId
        }), "*")
    }
    _setFetchedData(data, loadingNextData, stopReload) {
        super._setFetchedData(data, loadingNextData, stopReload);
        window.parent.postMessage(JSON.stringify({
            status: 'surveyLoaded',
            theme: this._getCurrentQuestionnaire().theme,
        }), "*")
    }
    _prepareSurveyState(newState) {
        let lastRespId = localStorage.getItem('lastRespondentId');
        if (!this.questionId && lastRespId) {
            lastRespId = null;
            localStorage.removeItem('lastRespondentId');
        }
        if (this.questionnaireId && this.questionId) {
            if (lastRespId) {
                this.fixedRespondentId = lastRespId;
                this.answerSubmitter.fixedRespondentId = lastRespId;
            }
            let sIndex = newState.questionnaires.findIndex((q) => q.id === this.questionnaireId);
            if (sIndex > -1) {
                let selectedQuestionnaire = newState.questionnaires[sIndex],
                    qIndex = selectedQuestionnaire.questions.findIndex((q) => q.id === this.questionId);

                if (qIndex > -1) {
                    newState['currentQuestionnaire'] = sIndex;
                    newState['startPage'] = qIndex + 1 < selectedQuestionnaire.questions.length ? qIndex + 1 : qIndex;
                    // Even if I'd get the previously answered questions, setting them would be messy...
                    // Should I stop the user from going back?
                }
            }
        }
        return newState;
    }

    _notifyPageChange(newPageData) {
        window.parent.postMessage(JSON.stringify({
            status: 'pageChange',
            newPage: newPageData.page,
            newPageIdx: newPageData.idx
        }), "*")
    }
}
