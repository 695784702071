import React from "react";

import { lengthClassH1 } from '../helpers';
import AndroidAddToHomeInstructions from './AndroidAddToHomeInstructions';
import Logo from './Logo';
import { BigButton } from '../ScrtWin/Buttons';

const H1_TEXT = 'Let’s get started';

const AddToHomeScreenAndroidTablet = (props) => (
    <div className="question nps add-to-home android-tablet">
        <div className="container top-section">
            <h1 className={lengthClassH1(H1_TEXT)}>{H1_TEXT}</h1>
            <p>
                You are provided with two options.<br />
                Choose option A if you’re not going to use the app in locked mode.<br />
                Choose option B if you’re going to use a Kiosk browser for locked mode.
            </p>
        </div>
        <div className="middle-section">
            <div className="container">
                <div className="option-boxes-wrap">
                    <div className="option-box instructions-list">
                        <h3><b>A.</b> Add app to home screen</h3>
                        <p>Make sure you are using Google Chrome Browser.</p>
                        <AndroidAddToHomeInstructions />
                    </div>
                    <div className="option-box">
                        <h3><b>B.</b> Use Kiosk browser</h3>
                        <p>Here’s your pairing code:</p>
                        <h2>{props.pairingCode}</h2>
                        <p>Provide this to your administrator.</p>
                        {props.switchBack && (
                            <BigButton style={{ marginTop: '20px' }} onClick={props.switchBack}>
                                Switch to standard pairing
                            </BigButton>)}
                    </div>
                </div>
            </div>
        </div>
        <div className="container bottom-section">
            <Logo hideWeUse />
        </div>
    </div>);

export default AddToHomeScreenAndroidTablet;
