/**
 * Created by gab on 19/10/17.
 */

import keys from 'object-keys';
import moment from "moment/moment";
import {logInfo} from "./helpers";
import { apiDelete, apiPost } from './requestUtils';

export default class AnswerSubmitter {
    constructor(deviceId, fixedRespondentId, respondentMetadata) {
        this.deviceId = deviceId;
        this.fixedRespondentId = fixedRespondentId;
        this.respondentMetadata = respondentMetadata;
        this.internalToExternalRespId = JSON.parse(localStorage.getItem('internalToExternalRespId')) || {};
        this.answerQueue = JSON.parse(localStorage.getItem('answerQueue')) || [];
        const maxIntRespIdFromInt2Ext = keys(this.internalToExternalRespId).length
            ? Math.max(...keys(this.internalToExternalRespId)) + 1
            : 0;
        const maxIntRespIdFromAnswerQueue = this.answerQueue.length
            ? Math.max(...this.answerQueue.map((ans) => ans ? parseInt(ans.internalRespondentId, 10) : 0))
            : 0;
        logInfo('maxIntRespIdFromInt2Ext', maxIntRespIdFromInt2Ext);
        logInfo('maxIntRespIdFromAnswerQueue', maxIntRespIdFromAnswerQueue);
        this.internalRespondentId = Math.max(maxIntRespIdFromInt2Ext, maxIntRespIdFromAnswerQueue) + 1;
        if (this.answerQueue.length) {
            this._dequeAnswers();
        }
    }

    _submitAnswerPackage(answerPackageOriginal, questionnaireId) {
        if (!this.deviceId) return;
        //ToDO: consider always using internalToExternalRespId to handle respondent ids
        let answerPackage = {...answerPackageOriginal};
        answerPackage['deviceId'] = this.deviceId;
        answerPackage['internalRespondentId'] = this.internalRespondentId;
        answerPackage['respondentId'] = this.fixedRespondentId
            || this.internalToExternalRespId[answerPackage['internalRespondentId']];
        if (this.respondentMetadata) {
            answerPackage['respondentMetaData'] = this.respondentMetadata;
        }
        answerPackage['questionnaireId'] = questionnaireId;
        answerPackage['loggedAt'] = moment().format();
        if (Array.isArray(answerPackage['optionId'])) {
            answerPackage['optionIds'] = answerPackage['optionId'];
            answerPackage['optionId'] = null;
        }
        this._queueAnswer(answerPackage);
    }

    _queueAnswer(answerPackage) {
        this.answerQueue.push({...answerPackage});
        localStorage.setItem('answerQueue', JSON.stringify(this.answerQueue));
        this._dequeAnswers();
        //this._startDequeuer();
    }

    /*
    _startDequeuer() {
        if (!this.dequeuer) {
            this.dequeuer = setInterval(this._dequeAnswers.bind(this), 60000);
        }
    }
    _stopDequeuer() {
        if (this.dequeuer) {
            clearInterval(this.dequeuer);
            this.dequeuer = null;
        }
    }
    */

    _dequeAnswers() {
        if (this.dequeueInProgress) return;

        // Shouldn't be necessary. Evaluate removing it later
        this._cleanAnswerQueue();

        if (!this.answerQueue.length) {
            //this._stopDequeuer();
            return;
        }

        this.dequeueInProgress = true;
        this._dequeAnswer(0);
    }

    _dequeAnswer(n, backoff = 0) {
        if (backoff >= 6) {
            // Max backoff is 6 which gives a max timeout of 64s
            backoff = 6;
        }
        if (n >= this.answerQueue.length || n >= 2000) {
            this.reached2000 = n >= 2000;

            this._cleanAnswerQueue();
            this.dequeueInProgress = false;
            return;
        }

        let ansPack = this.answerQueue[n];

        if (ansPack['internalRespondentId'] && !ansPack['respondentId']) {
            ansPack['respondentId'] = this.internalToExternalRespId[ansPack['internalRespondentId']] || null;
        }

        const request = ansPack.delete
            ? apiDelete(`answers/${ansPack['respondentId']}/${ansPack.questionId}`)
            : apiPost('answers', ansPack)

        request
            .then((data) => {
                if (!ansPack['respondentId']) {
                    this.internalToExternalRespId[ansPack['internalRespondentId']] = data.respondentId;
                    localStorage.setItem('internalToExternalRespId', JSON.stringify(this.internalToExternalRespId));
                    localStorage.setItem('lastRespondentId', data.respondentId);
                }
                this.answerQueue[n] = null;
                this._dequeAnswer(n + 1);
            })
            .catch((/*error*/) => {
                setTimeout(() => this._dequeAnswer(n, backoff + 1), 1000 * 2**backoff);
                /*if (error.name === 'ServerError') {
                    // Should I delete the answer if server fails? I will...
                    this.answerQueue[n] = null;
                    this._dequeAnswer(n + 1);
                } else {
                }*/
                /*
                } else if (navigator.onLine) {
                    // When the answer queue reaches 2000 we erase answers with errors from the first 1000 answers
                    if (this.reached2000 && n <= 1000) {
                        this.answerQueue[n] = null;
                    }
                    this._dequeAnswer(n + 1);
                    // sendError(
                    //     error.message + ' -- navigator.onLine -- ' + JSON.stringify(
                    //         {
                    //             n: n,
                    //             failingAnswer: this.answerQueue[n],
                    //             // answerQueue: localStorage.getItem('answerQueue'),
                    //             // internalToExternalRespId: localStorage.getItem('internalToExternalRespId'),
                    //             internalRespondentId: localStorage.getItem('internalRespondentId'),
                    //         }), 'AnswerSubmitter.js', 0, 0, new Error());
                } else {
                    // When the answer queue reaches 2000 we erase answers with errors from the first 1000 answers
                    if (this.reached2000 && n <= 1000) {
                        this.answerQueue[n] = null;
                    }
                    setTimeout(() => this._dequeAnswer(n + 1), 10000);
                    // sendError(error.message + ' -- ' + JSON.stringify(
                    //     {
                    //         n: n,
                    //         failingAnswer: this.answerQueue[n],
                    //         // answerQueue: localStorage.getItem('answerQueue'),
                    //         // internalToExternalRespId: localStorage.getItem('internalToExternalRespId'),
                    //         internalRespondentId: localStorage.getItem('internalRespondentId'),
                    //     }), 'AnswerSubmitter.js', 0, 0, new Error());
                }
                */
            });
    }
    _cleanAnswerQueue() {
        this.answerQueue = this.answerQueue.filter(Boolean);
        localStorage.setItem('answerQueue', this.answerQueue.length ? JSON.stringify(this.answerQueue) : null);
        // try {
        //     const intRespIdsInQueue = this.answerQueue.map((ansPack) => parseInt(ansPack.internalRespondentId, 10));
        //
        //     if(!intRespIdsInQueue.length) return;
        //
        //     const minInternalRespondentId = Math.min(...intRespIdsInQueue);
        //     Object.keys(this.internalToExternalRespId).forEach((k) => {
        //         if (k < minInternalRespondentId - 1) {
        //             delete this.internalToExternalRespId[k];
        //         }
        //     });
        //     localStorage.setItem('internalToExternalRespId', JSON.stringify(this.internalToExternalRespId));
        // } catch (err) {
        //     sendError(`Failed to Clean internalToExternalRespId -- ${err.message}`, 'AnswerSubmitter.js', 0, 0, err);
        // }

    }

    _increaseInternalRespondentId() {
        localStorage.setItem('internalRespondentId', ++this.internalRespondentId);
    }
}
