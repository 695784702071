export const DK_INTVAL = -2;
export const SKP_INTVAL = -1;
export const DK_ID = 2;
export const SKP_ID = 1;

const HOST_MAP = {
    live: { api: 'https://portal-api.greatrate.se', static: 'https://static.greatrate.se' },
    stage: { api: 'https://stage-portal-api.greatrate.se', static: 'https://stage-static.greatrate.se' },
    dev: { api: 'https://dev-portal-api.greatrate.se', static: 'https://dev-static.greatrate.se' },
    liveOld: { api: 'https://portal.greatrate.se', static: 'https://portal.greatrate.se' },
    stageOld: { api: 'https://stage.portal.greatrate.se', static: 'https://stage.portal.greatrate.se' },
    devOld: { api: 'https://dev.portal.greatrate.se', static: 'https://dev.portal.greatrate.se' },
};

const CURRENT_HOST_MAP = HOST_MAP[process.env.REACT_APP_BACKEND_ENV] || {};

export const API_HOST = process.env.REACT_APP_CUSTOM_API_HOST || CURRENT_HOST_MAP.api || '';
export const STATIC_HOST = process.env.REACT_APP_CUSTOM_STATIC_HOST || CURRENT_HOST_MAP.static || '';

export let DSN;
if (process.env.REACT_APP_BACKEND_ENV) {
    DSN = {
        'stage': "https://9e2d337b1df844d7b152f3712445f24c@o460533.ingest.sentry.io/5461252",
        'live': "https://8504c21e51054eab8e6a05c1b17028e4@o460533.ingest.sentry.io/5461250",
        'stage-apk': "https://e1007693eee447dc8f7d5ebdbfbf6238@o460533.ingest.sentry.io/5461253",
        'live-apk': "https://08352f3fb5d54ccbab6f645df180d44e@o460533.ingest.sentry.io/5460923",
    }[window.cordova ? `${process.env.REACT_APP_BACKEND_ENV}-apk` : process.env.REACT_APP_BACKEND_ENV]
} else if (!window.cordova) {
    DSN = {
        'https://stage.portal.greatrate.se': "https://9e2d337b1df844d7b152f3712445f24c@o460533.ingest.sentry.io/5461252",
        'https://portal.greatrate.se': "https://8504c21e51054eab8e6a05c1b17028e4@o460533.ingest.sentry.io/5461250",
    }[window.location && window.location.origin]
}

const dbgMatch = window.location.search.match(/\bdebug=(\d+)\b/);
export const DBG_LVL = dbgMatch && parseInt(dbgMatch[1], 10);

