import React from 'react';

import { BigButton } from './ScrtWin/Buttons';

export default class LoadingScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {onLine: navigator.onLine}
        this.setConnectionStatus = () => this.setState({onLine: navigator.onLine})
    }

    componentDidMount() {
        window.addEventListener('online', this.setConnectionStatus)
        window.addEventListener('offline', this.setConnectionStatus)
    }
    componentWillUnmount() {
        window.removeEventListener('online', this.setConnectionStatus)
        window.removeEventListener('offline', this.setConnectionStatus)
    }

    renderTitle() {
        if (this.props.isWaitingForSurveyAssignment) {
            return <h1>This device is connected.<br /> Waiting for a survey to be assigned.</h1>
        }
        if (window.cordova && !this.state.onLine) {
            return (
                <React.Fragment>
                    <h1>No Connection</h1>
                    <BigButton style={{ marginTop: '20px' }} onClick={this.props.openScrtWin}>
                        Connect to WiFi
                    </BigButton>
                </React.Fragment>);
        }
        return <h1>Loading survey</h1>;
    }
    render() {
        return (
            <div className={"loadingScreen" + (this.props.hidden ? ' d-none' : '')}>
                <header>{this.renderTitle()}</header>
                <div>
                    <div className="leftEye" />
                    <div className="rightEye" />
                    <div className="mouth" />
                    <div className="gr-loader-logo-wrapper">
                        <img src={process.env.PUBLIC_URL + '/gr-logo-white.png'} alt="GreatRate" />
                    </div>
                </div>
                <div className="content">
                    {this.props.isWaitingForSurveyAssignment
                        && 'Add your device and a survey to a collection point to start collecting valuable insights.'}
                </div>
            </div>)
    }
}
