import React from "react";

import { lengthClassH1 } from '../helpers';
import Logo from './Logo';

const H1_TEXT = 'Great Work';

const FinalPairingScreen = (props) => (
    <div className="question nps final-pairing-screen">
        <div className="container top-section">
            <h1 className={lengthClassH1(H1_TEXT)}>{H1_TEXT}</h1>
            <p>Here’s your new pairing code</p>
        </div>
        <div className="middle-section">
            <div className="container">
                <h2 className="pairing-code">{props.pairingCode}</h2>
            </div>
        </div>
        <div className="container bottom-section">
            <p className="visible-xs-block">Provide this to your administrator.</p>
            <Logo hideWeUse />
        </div>
    </div>);

export default FinalPairingScreen;
