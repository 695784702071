import React, {Component} from "react";
import "wicg-inert";

import {
    CheckboxBars,
    CheckboxImages,
    Factors,
    Nps,
    RadioBars,
    RadioImages,
    Smileys,
    Comments,
    TextLine
} from "./Questions";
import { getHeader, interactionHandler, logError, translate, isPrompt, lineMaxLength } from "./helpers";
import { getStaticImgUrl } from './utils';


export default class NormalPage extends Component {
    render() {
        let classes = 'page';
        if (this.props.pageNumber === this.props.currentPage) {
            classes += ' showing';
            if (this.props.previousPage < this.props.currentPage)
                classes += ' toBeShown';
            else // if (this.props.pageNumber > this.props.currentPage)
                classes += ' shown';
        } else if (this.props.pageNumber > this.props.currentPage) classes += ' toBeShown';
        else classes += ' shown';// if (this.props.pageNumber > this.props.currentPage)
        classes += ' page-' + this.props.page.type.family;

        if (this.props.animating && this.props.previousPage === this.props.pageNumber) {
            classes += ' animating'
        }

        let style;
        if (this.props.page.backgroundImageUrl && !this.props.poorCondition.disableBackground) {
            const overlayColor = this.props.baseTheme === "Light"
                ? 'rgba(255, 255, 255, .5)'
                : 'rgba(0, 0, 0, .5)';
            let overlay = 'linear-gradient(180deg, ' + overlayColor + ', ' + overlayColor + ')';
            style = {
                backgroundImage: overlay + ', url(' + getStaticImgUrl(this.props.page.backgroundImageUrl) + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            };
        } else if (this.props.page.backgroundColor) {
            style = {backgroundColor: this.props.page.backgroundColor};
        }

        const setInert = this.props.pageNumber !== this.props.currentPage && !isPrompt(this.props.page);
        return (
            <div className={classes} style={style} ref={node => node && (setInert ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}
                 onAnimationEnd={this.props.stopAnimation}>
                <div className="v-center-wrapper">
                    {this._getQuestion()}
                </div>
            </div>
        )
    }
    
    _getQuestion() {
        const minimumProps = {
            question: this.props.page,
            advancePage: () => this.props.advancePage(this.props.pageNumber),
            lang: this.props.lang,
        };
        const commonProps = {
            ...minimumProps,
            questionData: this.props.questionData,
            setSurveyData: this.props.setSurveyData,
            ref: "question",
            isDark: this.props.baseTheme === 'Dark',
            dkText: this.props.dkText
        };
        switch (this.props.page.type.family) {
            case 'smileys':
                if (this.props.page.type.tag === 'rating') {
                    return <RadioBars subType="rating" {...commonProps}/>;
                }
                return <Smileys {...commonProps}/>;
            case 'nps':
                return <Nps {...commonProps}/>;
            case 'image':
                if (this.props.page.maxAnswers === 1) {
                    return <RadioImages {...commonProps}/>;
                }
                return <CheckboxImages {...commonProps}/>;

            case 'multi':
                if (['gender', 'thumbs'].indexOf(this.props.page.type.tag) > -1) {
                    return <RadioImages {...commonProps}/>;
                }

                if (this.props.page.maxAnswers === 1) {
                    return <RadioBars subType="multi-bars" {...commonProps}/>;
                }
                return <CheckboxBars subType="multi-bars" {...commonProps}/>;
            case 'factors':
                return <Factors {...commonProps}/>;
            case 'comments':
            case 'text':
                if (this.props.page.type.tag === 'comments') {
                    return <Comments {...commonProps}
                                     setFooterVisibility={this.props.setFooterVisibility}
                                     putOverlay={this.props.animating && this.props.currentPage === this.props.pageNumber}
                                     resetTimer={this.props.resetTimer}
                                     proceedText={this.props.proceedText}
                                     placeholder={['android', 'ipad'].indexOf(window.webAppDevType) > -1 ? {
                                         'en': 'Touch here to start writing...',
                                         'sv': 'Tryck här för att börja skriva...'
                                     } : ''} />;
                }

                return <TextLine {...commonProps}
                                 setFooterVisibility={this.props.setFooterVisibility}
                                 putOverlay={this.props.animating && this.props.currentPage === this.props.pageNumber}
                                 resetTimer={this.props.resetTimer}
                                 placeholder={['android', 'ipad'].indexOf(window.webAppDevType) > -1 ? {
                                     'en': 'Touch here to start writing...',
                                     'sv': 'Tryck här för att börja skriva...'
                                 } : ''} />;

            case 'info':
                if (this.props.page.type.tag === 'info-start') {
                    return <WelcomePage {...minimumProps} />;
                } else if (this.props.page.type.tag === "info-select-lang") {
                    return <LanguagePage {...minimumProps} languages={this.props.languages} setLanguage={this.props.setLanguage} />
                } else {
                    return;
                }
            default:
                logError('undefined question type');
                logError(this.props.page.type);
        }

    }
}

export class WelcomePage extends Component {
    render() {
        let buttonText = (this.props.question.options && this.props.question.options.length && this.props.question.options[0].caption) || "Start";
        return (
            <div className="welcome-page-inner">
                <fieldset  className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <button type="button"
                            className="start-survey"
                            onClick={interactionHandler.bind(this)(this.props.advancePage)}>
                        {translate(buttonText, this.props.lang)}
                    </button>
                </fieldset>
            </div>
        )
    }
}

export class LanguagePage extends Component {
    _getLanguageButtons() {
        return this.props.languages.map((lang) =>
            <label className="option-wrap" key={lang.id}
                   onClick={interactionHandler.bind(this)(this._setLanguage(lang.code).bind(this))}>
                <input type="radio" className="not-selected" name="language" value={lang.code}
                       onClick={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}/>
                <figure>
                    <img className={"flag-icon " + lang.flagCss} src={'/transparent.gif'} alt={lang.name} />
                    <figcaption>{lang.name}</figcaption>
                </figure>
            </label>
        );
    }

    _setLanguage(lang) {
        return () => {
            this.props.setLanguage(lang);
            this.props.advancePage();
        }
    }

    render() {
        return (
            <div className="flags images">
                <fieldset  className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <div className={"flags-wrap options-wrap line-wrap line-wrap-size-" + lineMaxLength(this.props.languages.length)}>
                        {this._getLanguageButtons()}
                    </div>
                </fieldset>
            </div>
        )
    }
}
