import React from 'react';

import AdvancedSection from './AdvancedSection';
import InfoSection from './InfoSection';
import NetworkSection from './NetworkSection';

export class ScrtWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { advancedMode: false};
    }
    componentDidMount() {
        setTimeout(this.props.destroy, 30000);
    }

    render() {
        return (
            <div id="scrtWin">
                <button className="pop-out" aria-label="Close">
                    <div className="x-close" onMouseDown={this.props.destroy} onTouchStart={this.props.destroy}/>
                </button>
                {!this.state.advancedMode
                    ? (
                        <InfoSection
                            switchToAdvanced={() => this.setState({ advancedMode: 1 })}
                            deviceId={this.props.deviceId}
                        />)
                    : (<AdvancedSection switchToBasic={() => this.setState({ advancedMode: 0 })}/>)}
            </div>);
    }
}
const SCRT_WIN_SECTIONS = { info: 'Info', advanced: 'Advanced', network: 'Network' };

export class NewScrtWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { openSection: SCRT_WIN_SECTIONS.info, blocked: true };
    }
    componentDidMount() {
        this.resetCloseTimeOut();
    }
    resetCloseTimeOut() {
        if (this.closeTimeOut) {
            clearTimeout(this.closeTimeOut);
        }
        this.closeTimeOut = setTimeout(this.props.destroy, this.state.blocked ? 15000 : 60000);
    }
    setSection (openSection) {
        if ([SCRT_WIN_SECTIONS.network, SCRT_WIN_SECTIONS.advanced].includes(openSection) && this.state.blocked) {
            const pin = prompt('Pin:');
            if (pin !== '2244') {
                return;
            }
            this.setState({ blocked: false, openSection });
            setTimeout(() => this.resetCloseTimeOut(), 10);
            return;
        }
        this.setState({ openSection })
    }
    render () {
        const { openSection } = this.state;
        return <div id="newScrtWin" onClick={() => this.resetCloseTimeOut()}>
            <ul className="nav nav-pills nav-justified">
                {Object.values(SCRT_WIN_SECTIONS).map((section) => (
                    <li className={openSection === section ? 'active' : ''} key={section}>
                        <a
                            href={`#section/${section}`}
                            className={openSection === section ? 'active' : ''}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setSection(section);
                            }}
                        >
                            {section}
                        </a>
                    </li>
                ))}
                <li className="nav-item nav-link">
                    <a href="#close" onClick={(e) => {
                        e.preventDefault();
                        this.props.destroy();
                    }}>
                        <i className="fa fa-times" aria-hidden="true" />
                    </a>
                </li>
            </ul>
            {openSection === SCRT_WIN_SECTIONS.network && <NetworkSection />}
            {openSection === SCRT_WIN_SECTIONS.info && <InfoSection deviceId={this.props.deviceId} />}
            {openSection === SCRT_WIN_SECTIONS.advanced && <AdvancedSection />}
        </div>
    }
}
// export default ScrtWin;
export class WithScrtWin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {scrtWin: false, showBurger: false};
    }

    _loadScrtWin(event) {
        let winHeight = window.innerHeight, winWidth = window.innerWidth,
            clientX = event.clientX || event.pageX,
            clientY = event.clientY || event.pageY;
        if (clientY > 0.7 * winHeight && clientX > 0.25 * winWidth && clientX < 0.75 * winWidth) {
            if (!this.startOpening) {
                this.startOpening = setTimeout(function () {
                    this.bottomCounter = 0;
                    this.topLeftCounter = 0;
                    this.startOpening = null;
                    this.setState({showBurger: false});
                }.bind(this), 3000);
            }

            this.bottomCounter = (this.bottomCounter || 0) + 1;
            if (this.bottomCounter === 2) this.setState({showBurger: true});
        } else if (clientY < 0.35 * winHeight && clientX < 0.35 * winWidth && this.bottomCounter === 2) {
            this.topLeftCounter = this.topLeftCounter ? this.topLeftCounter + 1 : 1;
        } else {
            this.bottomCounter = 0;
            this.topLeftCounter = 0;
            this.setState({showBurger: false});
        }

        if (this.topLeftCounter === 2 && this.bottomCounter === 2) {
            this.setState({scrtWin: true, showBurger: false});
            this._fetchApiData && this._fetchApiData();
            if (this.state.questionnaires && this.state.questionnaires.length) {
                this._finishSurvey();
            }
        }
    }

    _closeScrtWin() {
        if (this.state.scrtWin) {
            this.setState({scrtWin: false});
            this.bottomCounter = 0;
            this.topLeftCounter = 0;
        }
    }
}

export const BurgerIcon = () =>
    <img
        alt="Burger Icon"
        src={`${process.env.PUBLIC_URL}/meny-icon-green.png`}
        style={{ position: 'absolute', top: '18vh', left: '8vh', zIndex: 19999, height: '35px', pointerEvents: 'none' }}
    />;
