import React from 'react';
import moment from 'moment';

import { fName } from '../utils';
import { ExitKiosk, CheckUpdate } from './Buttons';

class AdvancedSection extends React.Component {
    render () {
        return <div style={{ paddingTop: "30px" }}>
            <ExitKiosk />
            {window.cordova && <CheckUpdate />}
            {localStorage.getItem('answerQueue') &&
                <h3><b>AnswerQueue size:</b> {localStorage.getItem('answerQueue').length}</h3>}
            {localStorage.getItem('internalToExternalRespId') &&
                <h3>
                    <b>IntToExt mapping size:</b> {localStorage.getItem('internalToExternalRespId').length}
                </h3>}
            <br />
            <h3><b>Version Identifier:</b> {AdvancedSection.getVersionId()}</h3>
            <h3><b>Chrome Version:</b> {AdvancedSection.getChromeVersion()}</h3>
            <h3><b>Date (UTC):</b> {JSON.stringify(new Date())}</h3>
            <h3><b>Date (from Date().localeString):</b> {(new Date()).toLocaleString('se-SV')}</h3>
            <h3><b>Date (from Date()):</b> {(new Date()).toString()}</h3>
            <h3><b>Date (from moment):</b> {moment().format()}</h3>
            {this.props.switchToBasic && <button onClick={this.props.switchToBasic}>Back to Basic Mode</button>}
        </div>;
    }

    static getVersionId() {
        let verIdMatch = fName.match(/main\.(\w+)\.js/i);
        return verIdMatch ? verIdMatch[1] : 'DEV';
    }

    static getChromeVersion() {
        let chromeVersionMatch = navigator.userAgent && navigator.userAgent.match(/Chrome\/([^ ]+)/i);
        return chromeVersionMatch ? chromeVersionMatch[1] : 'NOT-CHROME';
    }
}

export default AdvancedSection;
