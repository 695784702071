import React from "react";

import { lengthClassH1 } from '../helpers';
import AndroidAddToHomeInstructions from './AndroidAddToHomeInstructions';
import Logo from './Logo';

const H1_TEXT = 'Save app to home screen';

const AddToHomeScreenAndroidMobile = (props) => (
    <div className="question nps add-to-home android-mobile">
        <div className="container top-section">
            <h1 className={lengthClassH1(H1_TEXT)}>{H1_TEXT}</h1>
            <p>Make sure you are using Google Chrome Browser.</p>
        </div>
        <div className="middle-section grey-bg">
            <div className="container">
                <AndroidAddToHomeInstructions />
            </div>
        </div>
        <div className="container bottom-section">
            <p>If you are following the steps above, ignore this code below.</p>
            <p className="pairing-code">{props.pairingCode}</p>
            <Logo hideWeUse />
        </div>
    </div>);

export default AddToHomeScreenAndroidMobile;
