import React from "react";

import iconAdd from "../css/pairing/icon-add.png";
import iconOpen from "../css/pairing/icon-open.png";
import iconShare from "../css/pairing/icon-share.png";
import { lengthClassH1 } from '../helpers';
import Logo from './Logo';

const H1_TEXT = 'Save app to home screen';

const AddToHomeScreenIpad = (props) => (
    <div className="question nps add-to-home ipad">
        <div className="container top-section">
            <h1 className={lengthClassH1(H1_TEXT)}>{H1_TEXT}</h1>
            <p>Make sure you are using Safari.<br /> Read all three steps before proceeding.</p>
        </div>
        <div className="middle-section instructions-list grey-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-4 text-right icon-wrapper">
                        <img src={iconShare} alt="Mac share icon" />
                    </div>
                    <div className="col-xs-8 text-instructions">
                        <p>1. Press this button.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-4 text-right icon-wrapper">
                        <img src={iconAdd} alt="Mac Add to Homescreen icon" />
                    </div>
                    <div className="col-xs-8 text-instructions">
                        <p>2. Choose “Add to home screen” and then press “Add”.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-4 text-right icon-wrapper">
                        <img src={iconOpen} alt="Open App icon" />
                    </div>
                    <div className="col-xs-8 text-instructions">
                        <p>3. Open app from home screen.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container bottom-section">
            <p>If you are following the steps above, ignore this code below.</p>
            <p className="pairing-code">{props.pairingCode}</p>
            <Logo hideWeUse />
        </div>
    </div>);

export default AddToHomeScreenIpad;
