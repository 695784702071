import React from "react";
import { DBG_LVL } from './constants';

export const defaultAnimationDuration = 500;
export let animationDuration;
export function setAnimationDuration(newAnimationDuration) {
    animationDuration = newAnimationDuration;
}
export function lengthClass(str) {
    return lengthClassMeta(str, [60, 90, 120, 150])
}
export function lengthClassH1(str) {
    return lengthClassMeta(str, [19, 40, 90, 120, 150])
}
export function lengthClassBar(str) {
    return lengthClassMeta(str, [19, 60, 90, 120, 150])
}

export function lengthClassMeta(str, lengthBorders) {
    const prefix = 'chars-';
    const strLength = str.length;
    let className = '';
    let i;
    for (i = 0; strLength > lengthBorders[i]; i++) {
        className += prefix + lengthBorders[i] + '-up ';
    }

    return className || (prefix + lengthBorders[0]  + '-down');
}
export function translate(str, lang) {
    if (typeof str === 'string' || str === null) {
        return str;
    }
    if (!(lang in str)) {
        if (window.defaultLanguage && window.defaultLanguage in str) {
            return str[window.defaultLanguage];
        }

        return '';
    }
    return str[lang];
}

export function getHeader(obj, lang, classes) {
    let h1_elem, h2_elem;

    if (obj.caption) {
        let caption = translate(obj.caption, lang);
        h1_elem = <h1 key="h1" className={lengthClassH1(caption)} tabIndex="-1">{caption}</h1>
    }
    if (obj.text && translate(obj.text, lang)) {
        let text = translate(obj.text, lang);
        h2_elem = <h2 key="h2" className={lengthClass(text)} tabIndex="-1">{text}</h2>
    }
    return <legend className={classes}>
        {h1_elem}
        {h2_elem}
    </legend>;
}

export function lineMaxLength( options_len ) {
    let options_len_to_line_length_array = {16: 4, 15: 5, 14: 5, 13: 5, 12: 4, 11: 4, 10: 5, 9: 5, 8: 4, 7: 4, 6: 3};
    if ( options_len > 16 ) return 5;
    if ( options_len <= 5 ) return options_len;

    return options_len_to_line_length_array[ options_len ];
}

export function logInfo( ...args ) {
    if (console && console.log) console.log(...args)
}
export const logOnDebugLvl = (dbgLvl = 0) => ( ...args ) => {
    if (DBG_LVL && DBG_LVL >= dbgLvl && console && console.log) console.log(...args)
}
export const logOnDebug = logOnDebugLvl();

export function logError( ...args ) {
    if (console && console.error) console.error(...args)
}
// let tmpLocalStorage = {};
// export class safeLocalStorage {
//     static setItem(item, value) {
//         try {
//             localStorage.setItem(item, value)
//         } catch (err) {
//             if (!tmpLocalStorage) tmpLocalStorage = {};
//             tmpLocalStorage[item] = value;
//         }
//     }
//
//     static getItem(item) {
//         try {
//             return localStorage.getItem(item) || tmpLocalStorage[item] || null;
//         } catch (err) {
//             return (tmpLocalStorage && tmpLocalStorage[item]) || null;
//         }
//     }
//
// }

export function isPrompt(question) {
    return ['info', 'info-end', 'info-end-download'].indexOf(question.type.tag) > -1;
}
export function isFactors(question) {
    return question.type.family === 'factors';
}
export function isTextInput(question) {
    return ['comments', 'text'].indexOf(question.type.family) > -1;
}


export function interactionHandler(handler) {
    return function(e) {
        if (this.handlingInteraction || (e.type === 'touchstart' && window.innerWidth < 480)) return;

        this.handlingInteraction = true;
        handler(e);
        setTimeout(() => this.handlingInteraction = false, e.type === 'touchstart' ? 1500 : 200);
    }.bind(this);
}

export function parseQueryParams() {
    const query = window.location.search.substring(1);
    let result = {};
    query.split("&").forEach((part) => {
        const item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1])
    });
    return result;
}
