import { STATIC_HOST } from './constants';

const scripts = document.getElementsByTagName("script");
export const fName = scripts && scripts[scripts.length-1].src;

const updateUrl = `${process.env.REACT_APP_UPDATE_HOST}/apk/version.xml`;

export const UPDATE_CODE = {
    APP_UPDATE_MISSING: 0,
    VERSION_NEED_UPDATE: 201,
    VERSION_UP_TO_UPDATE: 202,
    VERSION_UPDATING: 203,
    NETWORK_ERROR: 405,
};

export const checkUpdate = () => {
    return new Promise((resolve, reject) => {
        if (!navigator.onLine) {
            reject(UPDATE_CODE.NETWORK_ERROR)
        }
        if (!window.AppUpdate) {
            reject(UPDATE_CODE.APP_UPDATE_MISSING)
        }
        if (window.AppUpdate) {
            const to = setTimeout(() => window.KioskPlugin && window.KioskPlugin.exitKiosk(), 5000)
            window.AppUpdate.checkAppUpdate(
                ({ code }) => {
                    if (code === 202) {
                        clearTimeout(to);
                    }
                    resolve(code);
                },
                (error) => {
                    clearTimeout(to);
                    reject(error);
                },
                updateUrl,
                { 'skipPromptDialog' : true }
            );
        }
    });
}

export const mapBy = (arr, getKey) => {
    const res = {};
    if (arr) {
        arr.forEach((el) => res[getKey(el)] = el);
    }
    return res;
}

export const groupBy = (arr, getKey) => {
    const res = {};
    if (arr) {
        arr.forEach((el) => {
            const k = getKey(el);
            res[k] = res[k] ? [...res[k], el] : [el]
        });
    }
    return res;
}
export const isFactor = (q) => q && q.type && q.type.family === 'factors';

export const transformServerSideAnswer = ({ questionOptionId, ...answer }) => ({ optionId: questionOptionId, ...answer });
export const getQuestionSavedProgress = (qId) => window.userData && window.userData.surveyData && window.userData.surveyData[qId];
export const deleteQuestionProgress = (qId) => {
    if (window.userData && window.userData.surveyData && window.userData.surveyData[qId]) {
        delete window.userData.surveyData[qId];
    }
}

export const makeRespondentIsIn = (divisionIds) => {
    const respondentIsIn = (branch) => branch.some((n) => divisionIds.indexOf(n.id || n.Id) > -1 || respondentIsIn(n.divisions));

    return respondentIsIn;
}

export const findNode = (nodes, id) => {
    if (nodes.length === 0) return null;
    for (let i = 0; i < nodes.length; i++) {
        if ((nodes[i].id || nodes[i].Id) === id) return nodes[i];
        const newNode = findNode(nodes[i].divisions, id);
        if (newNode) return newNode;
    }
}

export const delay = (ms, resp) => new Promise((res) => setTimeout(() => res(resp), ms));

export const getStaticImgUrl = (imgUrl) => !imgUrl || imgUrl.match(/https?:\/\//) ? imgUrl : `${STATIC_HOST}${imgUrl}`;
