import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./css/bootstrap.css";
import "./css/font-awesome.min.css";
import "./css/open_sans.css";
import "./css/flag-icon-css/css/flag-icon.min.css";
import { Widget } from './Widget';
import { Home, WebApp, Previewer } from './App';

const GrRouter = () => (
    <Router basename="webapp">
        <div>
            <Route exact path="/" component={Home}/>
            <Route exact path="/:location" component={WebApp}/>
            <Route exact path="/device/:deviceId" component={WebApp}/>
            <Route exact path="/:location/start_page/:startPage" component={WebApp}/>
            <Route exact path="/preview/:questionnaireId/:questionId" component={Previewer}/>
            <Route exact path="/preview/:questionnaireId" component={Previewer}/>
            <Route exact path="/widget/:deviceId" component={Widget}/>
            <Route exact path="/widget/:deviceId/continue/:questionnaireId/:questionId" component={Widget}/>
        </div>
    </Router>
);

export default GrRouter
