import React, {Component} from "react";

import { API_HOST } from './constants';
import warningSvg from './css/warning.svg';
import { getHeader, interactionHandler, lineMaxLength, parseQueryParams, translate } from "./helpers";
import { apiRequest } from './requestUtils';


class Popup extends Component {
    render() {
        const classes = "gr-popup popped-" + (this.props.isShow ? 'up' : 'out') + ' ' + this.props.className;
        let closeButton;
        if (this.props.togglePrompt) {
            closeButton = <button className="pop-out" aria-label="Close">
                <div className="x-close" onClick={interactionHandler.bind(this)(this.props.togglePrompt)}/>
            </button>
        }
        return (
            <div className={classes} onAnimationEnd={this.props.onTransitionEnd}
                 onTransitionEnd={this.props.onTransitionEnd}
                 ref={node => node && (!this.props.isShow ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}>
                {closeButton}
                <div className={"v-center-wrapper " + (this.props.fluid ? "container-fluid" : "container")}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export const GoBackPrompt = ({show, toggleGoBackPrompt, onAccept, webAppTexts, lang}) => {
    if (!window.userData || !window.userData.isSendout) return;
    const dontShowAgain = (val) => {
        localStorage.setItem(`${window.userData.uId}-go-back`, val)
    }

    const getText = (key) => {
        return translate(webAppTexts[key], lang);
    }

    return (
        <Popup className="go-back" isShow={show} togglePrompt={toggleGoBackPrompt}>
            <img className="warning-icon" src={warningSvg} alt="warning"></img>
            <h3>{getText("WA-DeleteAnswerTitle")}</h3>
            <h5>{getText("WA-DeleteAnswerDescription")}</h5>
            <div className="dont-show-again-container">
                <input id="dont-show-again" type="checkbox" onChange={(e) => dontShowAgain(e.target.checked)}></input>
                <label htmlFor="dont-show-again">{getText("WA-DontShowAgain")}</label>
            </div>
            <button type="button" className="accept-btn start-survey" onClick={onAccept}>{getText("WA-DeleteAnswerConfirm")}</button>
        </Popup>
    )
}

export class LanguagePrompt extends Component {
    render() {
        return (
            <Popup className="flags images" isShow={this.props.isShow} togglePrompt={this.props.toggleLanguagePrompt}>
                <h1>{translate(this.props.chooseLanguageText, this.props.lang)}</h1>
                <div className={"flags-wrap options-wrap line-wrap line-wrap-size-" + lineMaxLength(this.props.languages.length)}>
                    {this._getLanguageButtons()}
                </div>
            </Popup>
        )
    }

    _getLanguageButtons() {
        return this.props.languages.map((lang) =>
            <label className="option-wrap" key={lang.id}
                   onClick={interactionHandler.bind(this)(this._setLanguage(lang.code).bind(this))}>
                <input type="radio" className="not-selected" name="language" value={lang.code}
                       onClick={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}/>
                {/*<img src={this.props.languages[lang].img_lg} alt={this.props.languages[lang].name}/>*/}
                <figure>
                    <img className={"flag-icon " + lang.flagCss} src={'/transparent.gif'} alt={lang.name} />
                    <figcaption>{lang.name}</figcaption>
                </figure>
                {/*<h2>{lang.name}</h2>*/}
            </label>
        );
    }

    _setLanguage(lang) {
        return () => this.props.setLanguage(lang);
    }
}

export class InfoPopup extends Component {
    render() {
        return (
            <Popup className="info" isShow={this.props.isShow} togglePrompt={this.props.toggleInfoPopup}>
                <div dangerouslySetInnerHTML={{__html: translate(this.props.info, this.props.lang)}}/>
                {/*{getHeader(this.props, this.props.lang)}*/}
                {/*<p>{translate(this.props.info, this.props.lang)}</p>*/}
            </Popup>
        )
    }
}

export class QuestionPrompt extends Component {
    constructor() {
        super();
        this.state = {
            resendAnswersDisabled: false,
            loading: true,
            showSendAgain: false
        }
        this.intervalId = null;
    }

    componentDidMount() {
        this.setState({ loading: true });
    
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
        this.intervalId = setInterval(() => {
            const answerQueue = JSON.parse(localStorage.getItem('answerQueue')) || [];
            if (window.userData && window.userData.isSendout && answerQueue.length > 0) {
                this.setState({showSendAgain: true});
            } else {
                this.setState({showSendAgain: false});
            }
        }, 400);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isShow && !prevProps.isShow) {
          this.setState({ loading: true });
    
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        }
      }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    async _exportAnswers() {
        const { lang, reportId } = this.props;
        const rId = parseQueryParams().rid || localStorage.getItem('lastRespondentId');
        if (reportId && reportId.length) {
            const dataUrl = `answers/download/forReport?respondentId=${rId}&surveyId=${window.surveyId}&resultReportId=${reportId}`;
            const reportUrl = `reports/public/${reportId}?respondentId=${rId}`;
            const surveyUrl = `package/questionnaire/${window.surveyId}`;
            try {
                const [slides, report, pack] = (await Promise.all([apiRequest(dataUrl), apiRequest(reportUrl), apiRequest(surveyUrl)]));
                const makeReport = await import('report-pptx-gen');
                await makeReport.default(
                    { title: 'The Title', slides },
                    report,
                    pack.questionnaires[0], // Assume that we find the questionnaire required
                    {
                        lang,
                        translateFn: translate,
                        filterText: "",
                        fileName: [report.title, new Date().toJSON().split('T')[0], lang].filter(Boolean).join('_'),
                    }
                );
            } catch (err) {
                console.error(err);
            }
        } else {
            const dlUrl = `${API_HOST}/api/v1/answers/download?respondentId=${rId}&surveyId=${window.surveyId}&languageCode=${lang}`;
            window.open(dlUrl, "_blank");
        }
    }

    _resendAnswers() {
        this.setState({ resendAnswersDisabled: true });
        setTimeout(() => {
            this.setState({ resendAnswersDisabled: false });
        }, 5000);
        this.props.resendAnswers();
    }

    render() {
        if (this.state.loading) {
            return (
                <Popup className="question-prompt" isShow={this.props.isShow} fluid={true}
                   onTransitionEnd={this.props.onTransitionEnd}>
                    <div className="spinner">
                    </div>
                </Popup>
            )
        }
        let dlSection, cancelSection, okSection, unansweredQuestionsSection;
        if (this.state.showSendAgain) {
            unansweredQuestionsSection = <div>
                <img className="warning-icon" src={warningSvg} alt="warning"></img>
                <h2>{translate(this.props.sendAgainTitleText, this.props.lang)}</h2>
                {!this.state.resendAnswersDisabled && <button className="start-survey" onClick={this._resendAnswers.bind(this)}>
                    {translate(this.props.sendAgainBtnText, this.props.lang)}
                </button>}
                {this.state.resendAnswersDisabled && <div className="resend-answers-container">
                    <div className="spinner"></div>
                    <h4>{translate(this.props.sendAgainLoadingText)}</h4>
                </div>}
            </div>
        } else {
            if (this.props.isShow && this.props.noText) {
                if (this.props.isDl) {
                    dlSection = <div className={this.props.yesText ? "col-sm-6 col-xs-12 pull-left-sm" : ''}>
                    <button className="start-survey dl" onClick={this._exportAnswers.bind(this)}>
                        {this.props.noText}
                    </button>
                </div>
                } else {
                    cancelSection = <div className={this.props.yesText ? "col-sm-6 col-xs-12 pull-left-sm" : ''}>
                        <button className="start-survey no" onClick={interactionHandler.bind(this)(this.props.setCancel)}>
                            {this.props.noText}
                        </button>
                    </div>
                }
            }
            if (this.props.isShow && this.props.yesText) {
                const className = (cancelSection && "col-sm-6 col-xs-12 pull-right-sm")
                    || (dlSection && "col-sm-6 col-xs-12 pull-right-sm")
                    || '';
                okSection = <div className={className}>
                    <button className="start-survey yes" onClick={interactionHandler.bind(this)(this.props.setConfirmation)}>
                        {this.props.yesText}
                    </button>
                </div>
            }
        }

        return (
            <Popup className="question-prompt" isShow={this.props.isShow} fluid={true}
                   onTransitionEnd={this.props.onTransitionEnd}>
                {!this.state.showSendAgain && getHeader(this.props, this.props.lang)}
                <div className="row">
                    {dlSection}
                    {okSection}
                    {cancelSection}
                    {unansweredQuestionsSection}
                </div>
            </Popup>
        )
    }
}


export class ThanksPopup extends Component {
    render() {
        return (
            <Popup className="thanks" isShow={this.props.isShow} onTransitionEnd={this._finishIn500.bind(this)}>
                {getHeader(this.props.thanksDialog, this.props.lang)}
                {this._getOkButton()}
            </Popup>
        )
    }

    _getOkButton() {
        if (this.props.thanksDialog.okText) {
            return <button className="start-survey" onClick={interactionHandler.bind(this)(this.props.finishSurvey)}>
                    {translate(this.props.thanksDialog.okText, this.props.lang)}
                </button>
        }
    }
    _finishSurvey() {
        // clearTimeout(this.timeout);
        this.props.finishSurvey();
    }
    _finishIn500() {
        if (!this.timeout) {
            // this.timeout = setTimeout(this.props.finishSurvey, 1000);
        }
    }
}
