import React from "react";

import AddToHomeScreenAndroidTablet from './AddToHomeScreenAndroidTablet';
import AddToHomeScreenAndroidMobile from './AddToHomeScreenAndroidMobile';

const AddToHomeScreenAndroid = (props) => (
    <React.Fragment>
        <AddToHomeScreenAndroidTablet {...props} />
        <AddToHomeScreenAndroidMobile {...props} />
    </React.Fragment>);

export default AddToHomeScreenAndroid;
